<script lang="ts" setup>
import type {
  ComponentBlockTeaserHintFragment,
  Enum_Componentblockteaserhint_Variation,
} from "~~/types/graphql";

import Arrow from "@/assets/icons/arrow.svg?component";
const props = defineProps<{
  TeaserHintAnchor: ComponentBlockTeaserHintFragment["TeaserHintAnchor"];
  TeaserHintHeadline: ComponentBlockTeaserHintFragment["TeaserHintHeadline"];
  TeaserHintLink: ComponentBlockTeaserHintFragment["TeaserHintLink"];
  TeaserHintLinkExternal: ComponentBlockTeaserHintFragment["TeaserHintLinkExternal"];
  TeaserHintLinkTarget: ComponentBlockTeaserHintFragment["TeaserHintLinkTarget"];
  TeaserHintSubheadline: ComponentBlockTeaserHintFragment["TeaserHintSubheadline"];
  TeaserHintTag: ComponentBlockTeaserHintFragment["TeaserHintTag"];
  TeaserHintText: ComponentBlockTeaserHintFragment["TeaserHintText"];
  TeaserHintVariation: Enum_Componentblockteaserhint_Variation;
}>();

function link() {
  let linkUrl = false;

  if (props.TeaserHintLinkTarget === "external") {
    linkUrl = props.TeaserHintLinkExternal || false;
  }
  // Default option 'page'
  else {
    linkUrl = props.TeaserHintLink.data?.attributes?.Slug || false;

    if (linkUrl && props.TeaserHintAnchor) {
      linkUrl = linkUrl + "#" + props.TeaserHintAnchor;
    }
  }

  return linkUrl;
}
</script>
<template>
  <layout-block
    :link="props.TeaserHintLink?.data?.attributes?.Slug"
    :class="`variation-${props.TeaserHintVariation?.toLowerCase()}`"
    class="teaser-hint"
  >
    <div
      class="mx-auto flex max-w-3xl flex-col items-center justify-center text-center"
    >
      <base-tag
        v-if="TeaserHintTag"
        class="teaser-tag border-light-grey bg-light-grey mb-3"
      >
        {{ TeaserHintTag }}
      </base-tag>
      <h2
        v-if="TeaserHintHeadline"
        class="mb-3 grid max-w-prose-xs place-content-center hyphens-auto text-center font-pixel text-h2 md:text-h1 2xl:text-h1max"
      >
        {{ TeaserHintHeadline }}
      </h2>
      <base-text v-if="TeaserHintText" class="mb-8" :Text="TeaserHintText" />
      <slot name="button">
        <base-button
          v-if="TeaserHintSubheadline && link()"
          :to="link()"
          class="teaser-button w-full justify-center border-integer-black hover:border-integer-black xs:w-auto"
          transparent
          type="secondary"
          >{{ TeaserHintSubheadline }}<template #icon-right><Arrow /></template
        ></base-button>
      </slot>
    </div>
  </layout-block>
</template>
<style scoped lang="scss">
.teaser-hint.variation-dark {
  @apply bg-integer-black text-integer-white;

  .teaser-button {
    @apply border-integer-white text-integer-white hover:border-integer-white hover:bg-integer-white hover:text-integer-black;
  }

  .teaser-tag {
    @apply border-light-black bg-light-black text-integer-white;
  }
}

.teaser-hint.variation-green {
  @apply bg-integer-light-green;

  .teaser-tag {
    @apply border-integer-white bg-integer-white;
  }
}

.teaser-hint.variation-grey {
  @apply bg-light-gray;

  .teaser-tag {
    @apply border-integer-white bg-integer-white;
  }
}
</style>
